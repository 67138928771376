import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import ClassroomLayout from "../layout/classroom-layout"
import { Link } from "gatsby"
import { Card, Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClock,
  faBookOpen,
  faGaugeHigh,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons"
import ApiService from "../../../services/ApiService"
import Breadcrumbs from "../shared/breadcrumb"
import { useDispatch } from "react-redux"
import { setContextSidebar } from "../../../redux/app-actions"
import { toast } from "react-toastify"

export default function Course(props) {
  const breadcrumbs = props.pageContext.breadcrumb
  const course = props.data.scocms.getCourse.data

  const dispatch = useDispatch()

  const lstModules = []
  for (const [, m] of course.courseStructure.entries()) {
    if (m.__typename === "SCOCMS_Module") {
      m.count = Object.keys(m.topics).length
      lstModules.push(m)
    }
    if (m.__typename === "SCOCMS_Exam") {
      lstModules.push(m)
    }
  }

  const [modules, setModules] = useState(lstModules)

  useEffect(async () => {
    const _modules = [...modules]

    const uep = await ApiService.getUserExam(course?.moduleTestExam?.id)

    if (uep?.progressObject) {
      for (const [key, val] of Object.entries(JSON.parse(uep.progressObject))) {
        if (val) {
          const testid = key.split("#")[0]
          const io = _modules.findIndex(_m => _m?.test?.id.startsWith(testid))
          const m = _modules[io]
          if (m) {
            m.completed = true
            _modules[io] = m
          }
        }
      }

      dispatch(setContextSidebar({ course }))

      setModules(_modules)
    }
  }, [])

  const modulesCompleted = modules.every(m => m.completed)

  return (
    <ClassroomLayout>
      <section className="site-header">
        <Container>
          <Row>
            <Col xs="auto" className="d-flex align-items-center">
              <FontAwesomeIcon fontSize={"50px"} icon={faGaugeHigh} />
            </Col>
            <Col>
              <h1>{course.name}</h1>
              <p>{course.description}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <Container className="pb-5">
        <div className="mb-4">
          <Breadcrumbs links={breadcrumbs} />
        </div>
        {modulesCompleted ? (
          <Card>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col>
                    <h2>Congratulations on completing a SUP Assist course.</h2>
                    <p style={{ margin: 0 }}>
                      Here&apos;s a discount code to redeem a FREE Moloko Waist
                      leash Belt from TheSupCo website.
                    </p>
                    <p>
                      <small>
                        *Customers to pay shipping to receive a the FREE Moloko
                        Waist Leash Belt
                      </small>
                    </p>
                    <DiscountCode />
                  </Col>
                  <Col
                    xs="auto"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      className="img-fluid"
                      style={{ maxWidth: "400px" }}
                      src="https://supassist-storage-82e1a66592118-release.s3.eu-west-2.amazonaws.com/public/moloko-waist-belt.jpg"
                    />
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        ) : (
          <>
            <h2>Modules</h2>
            <Row>
              {modules?.map(m => (
                <>
                  <Col className="mb-4" xs="12" md="3" key={m.id}>
                    {m.__typename == "SCOCMS_Exam" ? (
                      <Link
                        to={"/classroom/" + course.slug + "/exam/" + m.slug}
                      >
                        <Card className={`course-module-card`}>
                          <Card.Body>
                            <Card.Title>{m.name}</Card.Title>
                          </Card.Body>
                        </Card>
                      </Link>
                    ) : (
                      <Card
                        className={`course-module-card ${
                          m.completed ? "completed" : ""
                        }`}
                      >
                        <Link to={"/classroom/" + course.slug + "/" + m.slug}>
                          <Card.Body>
                            <Card.Title>
                              {m.name}
                              {m.completed ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon={faCircleCheck}
                                />
                              ) : (
                                ""
                              )}
                            </Card.Title>
                            <FontAwesomeIcon
                              icon={faClock}
                              className={"me-2"}
                            />
                            {m.hours} minutes
                            <br />
                            <FontAwesomeIcon
                              icon={faBookOpen}
                              className={"me-2"}
                            />
                            {m.count} Topics
                          </Card.Body>
                        </Link>
                      </Card>
                    )}
                  </Col>
                </>
              ))}
            </Row>
          </>
        )}
      </Container>
    </ClassroomLayout>
  )
}

const CODE = "SUPASSIST1"

const DiscountCode = () => {
  const handleCodeClick = () => {
    navigator.clipboard.writeText(CODE)
    toast("Code copied to clipboard", { type: "success" })
  }

  return (
    <Card
      style={{ display: "inline-block", cursor: "pointer" }}
      onClick={handleCodeClick}
    >
      <Card.Body>
        <Card.Text>
          <div>
            CODE: <strong>{CODE}</strong>
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export const query = graphql`
  query ($id: ID) {
    scocms {
      getCourse(where: { id: $id }) {
        data {
          id
          moduleTestExam {
            id
          }
          description
          name
          slug
          courseStructure {
            ... on SCOCMS_Module {
              __typename
              id
              name
              slug
              hours
              test {
                id
              }
              topics {
                __typename
                id
                slug
                topics {
                  __typename
                  id
                  name
                  slug
                }
              }
            }
            ... on SCOCMS_Exam {
              __typename
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`
